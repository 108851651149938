import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { numberWithSpace } from '@/utils/numberWithSpaces'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Slider.module.scss'

interface Props extends DefaultComponentProps {
  min: number
  max: number
  value?: number
  step?: number
  onChange: (v: number) => void
}
export const Slider: FC<Props> = ({ max, className = '', onChange }) => {
  const MIN = 0
  const MAX = 100
  const CHANGING_TOOLTIP_POSITION = 10
  const STEP = 1
  const THUMB_WIDTH = 24
  const { t } = useTranslation()
  const [value, setValue] = useState(MIN)
  const [changing, setChanging] = useState(false)

  const handleRangeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value
    e.target.style.setProperty('--val', value + '')
    setValue(value)
  }, [])

  const handleRangeStart = useCallback(() => {
    setChanging(true)
  }, [])

  const returnValue = useMemo(() => +((value * max) / MAX).toFixed(0), [value])

  useEffect(() => {
    const handleRangeEnd = () => {
      if (changing) {
        setChanging(false)
        onChange(returnValue)
      }
    }

    document.addEventListener('mouseup', handleRangeEnd)
    document.addEventListener('touchend', handleRangeEnd)

    return () => {
      document.removeEventListener('mouseup', handleRangeEnd)
      document.removeEventListener('touchend', handleRangeEnd)
    }
  }, [returnValue, changing])

  return (
    <div className={clsx(styles['slider'], className)}>
      <label className={styles['slider__label']}>
        {changing && (
          <div
            className={clsx(styles['slider__tooltip'], {
              [styles['slider__tooltip_position-end']]:
                value > MAX - CHANGING_TOOLTIP_POSITION,
              [styles['slider__tooltip_position-start']]:
                value < CHANGING_TOOLTIP_POSITION && value !== MIN
            })}
            style={{
              left: `calc(${value}% - ${(value * THUMB_WIDTH) / MAX}px)`
            }}
          >
            {numberWithSpace(returnValue)}
          </div>
        )}
        <input
          type="range"
          min={MIN}
          max={MAX}
          step={STEP}
          value={value}
          className={styles['slider__range']}
          onChange={handleRangeChange}
          onMouseDown={handleRangeStart}
          onTouchStart={handleRangeStart}
          style={
            {
              '--min': MIN,
              '--max': MAX,
              '--val': value
            } as React.CSSProperties
          }
        />
      </label>
      <div className={styles['slider__footer']}>
        <div className={styles['slider__min']}>
          {value > 0
            ? t('reward-calculator:value', {
                value: numberWithSpace(returnValue)
              })
            : t('reward-calculator:turnover')}
        </div>
        <div className={styles['slider__max']}>
          {t('reward-calculator:max_value', { value: numberWithSpace(max) })}
        </div>
      </div>
    </div>
  )
}
